<template>
  <WidgetLayout :payload="data"/>
</template>

<script>
import WidgetLayout from "@/components/WidgetLayout.vue"
import definissez from '../../../public/json/decret-tertiaire-definissez.json'
import slides from '../../../public/json/decret-tertiaire-slides.json'

export default {
  name: "Define-your-strategy",
  components: {
    WidgetLayout
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez comment définir votre stratégie de réponse au décret tertiaire : vision claire du parc, collecte de données, dépôt sur OPERAT, etc.'
    }]
  },
  computed: {
    data() {
      return definissez.map(m => {
        if (m.type === 'slider') {
          return {
            slides: slides,
            ...m
          }
        } else {
          return {
            ...m
          }
        }
      })
    }
  }
}
</script>